@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

:root {
  --primary: rgb(51, 57, 138);
  --secondary: rgb(194, 67, 137);
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: poppins;
}

hr {
  max-width: 100%;
  margin: 0;
}

#Link {
  text-decoration: none;
  color: black;
}

.btn-custom {
  background-color: var(--primary);
  color: white;
}
.main-background {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-image: url("https://img.freepik.com/premium-vector/shopping-doodle-outline-seamless-pattern-background-vector-illustration_7087-2895.jpg?size=626&ext=jpg&ga=GA1.1.1334965056.1689834116&semt=ais");
}

.logo {
  text-decoration: none;
  color: white;
}

.logo-mob {
  display: none;
}
.loginpage {
  width: 70%;
}
.login-box {
  background-color: white;
  border-radius: 5px;
  padding: 1.5rem;
}

.password {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid rgb(207, 207, 207);
  border-radius: 5px;
  padding: 3px 0rem;
}

/* .password::-moz-focus-inner{
  border-color: #1e8cfa;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(0, 106, 255, 0.6);
} */

.password > input {
  border: none;
  outline: none;
  width: 90%;
  padding-left: 0.5rem;
  cursor: text;
}

/* .password input:focus{
  border-color: #1e8cfa;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(0, 106, 255, 0.6);
} */

.pass-logo {
  font-size: large;
  cursor: pointer;
}

.otp-box {
  margin: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}
.otp-field {
  display: flex;
  justify-content: center;
  gap: 10px;
}
.otp-field input {
  width: 40px;
  height: 40px;
  border-radius: 5px;
  outline: none;
  border: 1px solid var(--primary);
  text-align: center;
}

.otp-field > input:focus {
  border: 2px solid green;
}

.home {
  margin-top: 0px;
}

.navbar {
  background-color: var(--primary);
  display: flex;
  color: whitesmoke;
  padding: 0.5rem 1.2rem;
}

.navbar-link {
  display: flex;
  gap: 20px;
  justify-content: space-between;
}

.nav-link {
  text-decoration: none;
  font-size: 1rem;
}
.footer-box {
  margin: 0.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.footer-sticky {
  /* display: none; */
  position: -webkit-sticky;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: rgb(255, 255, 255);
}

.navbar-icon-style-footer {
  text-align: center;
  position: relative;
  font-size: 1rem;
  color: rgba(33, 33, 33, 0.914);
}

.navbar-icon-style-footer > div > a {
  text-decoration: none;
}

.cart-icon {
  position: relative;
}

.cart-span {
  position: absolute;
  background-color: rgb(67, 103, 244);
  color: white;
  font-size: 0.7rem;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  top: 0;
  right: 0;
  transform: translate(30%, -30%);
}
.search {
  display: flex;
  text-align: center;
  justify-content: space-around;
  align-items: center;
  gap: 1rem;
  margin: 1rem 1.5rem;
}

.search-bar {
  /* position: sticky; */
  align-items: center;
  padding: 3px 6px;
  width: 100%;
  /* border-radius: 5px; */
  border: 1px solid black;
}

.search-icon {
  font-size: 1.6rem;
  cursor: pointer;
}

.products {
  width: 100%;
  padding-bottom: 4.5rem;
  display: flex;
  /* background-image: url("https://img.freepik.com/free-photo/abstract-blue-geometric-shapes-background_24972-1841.jpg?size=626&ext=jpg&ga=GA1.1.1334965056.1689834116&semt=ais"); */
  flex-wrap: wrap;
  justify-content: space-around;
}

.skull-products {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.prod-card {
  width: 10rem;
  text-decoration: none;
  color: black;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 2.5px;
  align-items: center;
  position: relative;
  padding: 0.5rem;
  margin: 0.5rem;
}

.skull-prod-card {
  width: 10rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 2.5px;
  padding: 0.5rem;
  margin: 0.5rem;
  animation: skeleton-border 1s linear infinite alternate;
}

.prod-card .product-rear-image {
  opacity: 0;
  position: absolute;
  bottom: 20;
  left: 0;
  z-index: -1;
  transition: opacity 1s ease-out;
}

.product-front-image {
  padding: 10px 0px;
  width: 10rem;
  height: 10rem;
  display: inline-block;
  object-fit: contain;
}

.skull-image-container {
  width: 100%;
  height: 9.4rem;
  animation: skeleton-animation 1s linear infinite alternate;
}

.skull-product-name {
  margin-top: 5px;
  height: 0.5rem;
  width: 100%;
  animation: skeleton-animation 1s linear infinite alternate;
}

.skull-product-price {
  height: 0.5rem;
  width: 90%;
  animation: skeleton-animation 1s linear infinite alternate;
}

.skull-product-add-btn {
  height: 0.5rem;
  width: 80%;
  animation: skeleton-animation 1s linear infinite alternate;
}

.prod-card:hover .product-rear-image {
  opacity: 1;
  z-index: 0;
}
.prod-card:hover .product-front-image {
  opacity: 0;
}
.product-rear-image {
  padding: 10px 0px;
  width: 10rem;
  height: 10rem;
  display: inline-block;
  object-fit: contain;
}

.product-name {
  text-transform: capitalize;
}

.product-price {
  font-size: 0.7rem;
}

.product-add-btn {
  font-weight: 500;
  font-size: 0.6rem;
  background-color: var(--primary);
  text-transform: uppercase;
  color: white;
  border-radius: 5px;
  border: none;
  outline: none;
  width: 80px;
  height: 30px;
  display: block;
  cursor: pointer;
  background-image: linear-gradient(
    45deg,
    var(--secondary) 50%,
    transparent 50%
  );
  background-size: 300%;
  background-position: 100%;
  transition: all 0.5s ease;
}

.product-add-btn:hover {
  background-position: 0;
}

.product-add-btn:active {
  transform: scale(0.96);
}

@keyframes skeleton-animation {
  0% {
    background-color: hsl(200, 20%, 80%);
  }

  100% {
    background-color: hsl(200, 20%, 95%);
  }
}
@keyframes skeleton-border {
  0% {
    box-shadow: 0px 10px 15px -3px hsl(200, 20%, 80%);;
  }

  100% {
    box-shadow: 0px 10px 15px -3px hsl(200, 20%, 95%);
  }
}
.hide {
  display: none;
}
.details-container {
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  padding: 0.5rem;
  padding-bottom: 7rem;
  gap: 20px;
}

.show-title {
  text-transform: uppercase;
}
.show-image img {
  width: 200px;
  padding: 0 0.5rem;
  height: 300px;
  text-align: center;
  object-fit: contain;
}

.show-title-content {
  display: flex;
  padding: 0.7rem 0;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.show-price {
  margin-right: 3rem;
}
.show-title {
  width: 60%;
  text-align: start;
  color: gray;
  opacity: 5px;
  font-weight: medium;
  font-size: 1rem;
}
.show-icon {
  display: flex;
  font-size: 0.8rem;
  gap: 10px;
  cursor: pointer;
}

.show-icon p {
  font-size: 0.7rem;
}

.show-size {
  text-align: start;
  width: 100%;
}

.select-size {
  display: flex;
  justify-content: space-between;
  padding: 0.7rem;
  gap: 25px;
  overflow-x: scroll;
}

.sizes {
  padding: 0.5rem;
  font-weight: 500;
  text-align: center;
  font-size: 1rem;
  height: 40px;
  width: 40px;
  border-radius: 10px;
  border: 2px solid rgb(51, 57, 138);
}

.sizes:hover {
  background-color: rgb(51, 57, 138);
  color: white;
  outline: none;
}

.sizes-active {
  background-color: rgb(51, 57, 138);
  color: white;
}
.show-var {
  text-align: start;
  width: 100%;
}

.select-var {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 0.7rem;
  gap: 5px;
}

.var {
  display: flex;
  padding: 0.5rem;
  font-weight: 500;
  text-align: center;
  font-size: 1rem;
  height: 40px;
  border-radius: 10px;
  border: 2px solid rgb(51, 57, 138);
}

.var:hover {
  background-color: rgb(51, 57, 138);
  color: white;
  outline: none;
}

.var-active {
  background-color: rgb(51, 57, 138);
  color: white;
}

.show-details {
  margin-right: 4rem;
}
.show-details p {
  text-align: start;
  font-size: 0.8rem;
  margin: 0;
}

.show-details-footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: rgb(255, 255, 255);
  padding: 0.7rem 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.btn-buy {
  background-color: rgb(51, 57, 138);
  color: white;
  font-size: 1.2rem;
  font-weight: 600;
}

.btn-buy:hover {
  background-color: rgb(45, 50, 117);
  color: white;
}

.btn-icon {
  outline: 2px solid rgb(51, 57, 138);
  font-weight: 500;
  font-size: 1.05rem;
}

.show-popup {
  position: fixed;
  bottom: 0;
  z-index: 5;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 25px 25px 0 0;
  transition: all 5s ease-in-out;
}

.popup-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 4;
}

.slider-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 4;
}

.cart-nav {
  display: flex;
  justify-content: space-between;
  padding: 0.7rem;
}

.nav-pages {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 0.7rem;
}

.nav-pages-num {
  border: 2px solid rgb(51, 57, 138);
  border-radius: 50%;
  width: 20px;
  height: 20px;
  text-align: center;
  font-size: 0.7rem;
}

.cart-details {
  padding-bottom: 5rem;
}
.cart-details-container {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  align-items: center;
  width: 100%;
  border-bottom: 0.5px solid lightgrey;
}

.cart-details-container > img {
  width: 100px;
  height: 100px;
  margin: 0;
  padding: 0;
  object-fit: contain;
}

.cart-items {
  width: 65%;
  padding: 1.2rem;
  font-size: small;
}

.cart-footer {
  display: flex;
  padding: 0.7rem;
  background-color: white;
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.btn-bottom {
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  padding: 12px;
  text-align: center;
}

.btn-bottom > .btn-buy {
  font-size: 0.9rem;
  padding: 0.7rem 4rem;
}
.address-form {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 10px;
  height: 50vh;
}
.address-input {
  padding: 0.5rem;
  margin-top: 25px;
  border: none;
  outline: none;
  border-bottom: 0.5px solid gray;
}

.address-bg {
  padding: 0.9rem;
  background-color: rgb(229, 242, 255);
}

.btn-address > .btn-buy {
  width: 90%;
  padding: 0.7rem 4rem;
  font-size: 0rem;
}

.address-font {
  font-size: 0.85rem;
}

.head-nav {
  background-color: rgb(51, 57, 138);
  color: white;
}

.order-placed-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: url("https://img.freepik.com/premium-vector/shopping-doodle-outline-seamless-pattern-background-vector-illustration_7087-2895.jpg?size=626&ext=jpg&ga=GA1.1.1334965056.1689834116&semt=ais");
  height: 100vh;
}
.order-placed {
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
  border-radius: 0.7rem;
}

.order-details-container {
  display: flex;
  padding: 0.9rem;
  justify-content: space-between;
  width: 100%;
  background-color: #ffffff;
}
.order-details-container > img {
  width: 100px;
  height: 100px;
  margin: 0;
  padding: 0;
  object-fit: contain;
}
.orders {
  border-bottom: 0.5px solid lightgrey;
  padding: 0.6rem;
  background-color: #e5f2ff;
}
.order {
  background-color: #33398a;
}
.order > h5 {
  text-align: center;
  font-style: italic;
  color: white;
  padding: 0.7rem;
}

.cross-icon {
  color: white;
  margin-top: 0.3rem;
  font-size: 1.5rem;
}
.account-name {
  padding: 1rem 1rem 0;
}

.navbar-side-box {
  width: 20%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 15;
  height: 100%;
  background-color: rgb(248, 248, 248);
  font-size: 1rem;
  color: rgb(48, 48, 48);
  transition: left 0.3s ease;
}

.navbar-side-box.open {
  left: 0;
}
.hamburger-icon {
  scale: 1.3;
}

.black-fontcolor {
  color: rgb(48, 48, 48);
}
.white-fontcolor {
  color: white;
}

.hamburger {
  position: fixed;
  top: 1rem;
  right: 1rem;
  z-index: 10;
}

.list-data {
  font-size: small;
  padding: 1rem;
}
.list-data > a {
  color: rgb(48, 48, 48);
  text-decoration: none;
}

.list-data > a > p {
  margin-bottom: 1rem;
}

.account-name > small {
  font-size: 0.6rem;
}
@media only screen and (max-width: 600px) {
  .navbar {
    background-color: #33398a;
    height: 15%;
    color: whitesmoke;
    padding: 0.3rem 0.7rem;
  }

  .navbar span {
    font-size: 0.8rem;
  }

  /* .nav-link {
    display: none;
  } */

  .footer-sticky {
    position: -webkit-sticky;
    position: fixed;
    z-index: 5;
    left: 0;
    bottom: 0;
    width: 100%;
    background-color: rgb(255, 255, 255);
  }

  .footer-box {
    margin: 0.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .navbar-icon-style-footer {
    text-align: center;
    font-size: 0.8rem;
    color: rgba(33, 33, 33, 0.914);
  }

  .s-bg {
    position: -webkit-sticky; /* Safari */
    position: sticky;
    top: 0;
    z-index: 2;
    background-color: white;
    padding: 5px 0px;
  }
  /* .sizes:hover{
    background-color : white;
    color: black;
    outline: 3px solid rgb(51, 57, 138);
  } */

  .navbar-side-box {
    width: 60%;
  }
  .sizes-active {
    background-color: rgb(51, 57, 138);
    color: white;
  }
  /* .show-details-footer{
    gap: 20px;
  }
  .btn-buy,.btn-icon{
    width: 80%
  } */
}
